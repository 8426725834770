<template>
       <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
            <div class="workers-info-title-za">
                <div class="title">{{$t("message.additional_work")}}</div>
            </div>

            <div class="m-3">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="refa__chart">
                            <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-warning__night'">
                                <i
                                    class="my-icon el-icon-tickets text-primary"
                                ></i>
                                <div class="mobile__izn">
                                  <div class="text-center">
                                    <span class="text-primary">
                                        {{$t("message.total_qty")}}
                                    </span>
                                  </div>
                                  <div class="text-center mt-3">
                                      <b class="text-primary">{{additionals.length}}</b>
                                  </div>
                                </div>
                            </el-card>
                        </div>
                    </el-col>

                    <el-col :span="24">
                        <div class="scrol-table crmhome__scroll">
                            <table
                                :class="mode ? 'table__myday' : 'table__mynight'"
                                class="
                                    table__layout
                                    table-my-code table-bordered
                                    my-table-card my-clendar
                                "
                            >
                                <thead>
                                    <tr>
                                        <th class="w50p" scope="col">{{$t("message.n")}}</th>
                                        <th scope="col">{{$t("message.date_time")}}</th>
                                        <th scope="col">{{$t("message.employees")}}</th>
                                        <th scope="col">{{$t("message.reason")}}</th>
                                        <th scope="col">{{$t("message.performance_type")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(additional, index) in additionals"
                                    :key="'additional-' + index">
                                        <td>{{ index+1 }}</td>
                                        <td>  {{ additional.work_date_time_from}}<br v-if=" additional.work_date_time_from">{{ additional.work_date_time }}</td>
                                        <td>{{
                                              additional.staff
                                                ? additional.staff.name + " " + additional.staff.last_name
                                                : ""
                                            }}
                                          </td>
                                          <td>  {{
                                                    additional.reason
                                                      ? additional.reason.name: ""
                                                  }}
                                          </td>
                                        <td>
                                          {{
                                              additional.performance_type==1?"To'lovli":"To'lovsiz"
                                          }}  
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name:'additionalWork',
  props: {
      staff_id: {
        type: Number,
      },
    },

  data() {
    return {
      additionals:{},
    };
  },
  mounted() {
    this.table();
  },
  computed: {
    ...mapGetters({
      list: "additional/list",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "additional/additional",
    }),

     table(){
      const query = { staff_id: this.staff_id};
      this.updateList(query).then((res) => {
        this.additionals = res.data.result.data.additional;
      });
    },
  }
}
</script>
